import App from "next/app";
import "../assets/_app.css";
import theme from "../theme";
import { ConsentProvider } from '../utils/useConsent';

class MyApp extends App {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }
      return {
        pageProps,
      };
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId,
      };
    }
  }

  render() {
    const { Component, pageProps, router } = this.props;

    const cookieTypes = {
      // marketing: {
      //   id: "marketing",
      //   title: "Marketing cookies",
      //   components: [{ title: "Facebook" }, { title: "Instagram" }],
      // },
      analytical: {
        id: "analytical",
        title: "Anonieme analytische cookies",
        components: [{ title: "Google Analytics" }],
        enforce: true,
      },
      functional: {
        id: "functional",
        title: "Functionele cookies",
      },
    };

    const importedTheme = theme;

    return (
      <ConsentProvider>
        {process.env.NEXT_PUBLIC_GTM && (
               <script
               // strategy="afterInteractive"
               id='GTM_init'
               dangerouslySetInnerHTML={{
                 __html: `
                     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                     })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM}');
                   `,
               }}
             />
        )}
        <Component {...pageProps} />
      </ConsentProvider>
    );
  }
}
export default MyApp;
